<template>
  <div id="province-group-detail">
    <el-container style="display:block;">
      <el-row>
        <el-col :span="24">
          <div class="cardTitle">小区列表</div>
        </el-col>
        <el-col :span="18">
            <el-input placeholder="请输入小区名称" v-model="group.name">
              <template slot="prepend">小区名称</template>
            </el-input>
        </el-col>
        <el-col :span="6">
          <el-button type="primary" @click="doSubmit" class="cardTitleBtn" style="margin-left:10px;">保存</el-button>
          <el-button type="danger" @click="doDelete" class="cardTitleBtn" style="margin-left:10px;">删除</el-button>
          <el-button @click="addCommunity" class="cardTitleBtn">添加</el-button>
        </el-col>
        <el-col :span="24">
         <el-table :data="group.communityList" :default-sort = "{prop: 'account', order: 'desc'}" style="width: 100%">
          <el-table-column prop="provinceName" label="省" sortable></el-table-column>
          <el-table-column prop="cityName" label="市" sortable></el-table-column>
          <el-table-column prop="districtName" label="区" sortable></el-table-column>
          <el-table-column prop="streetName" label="街道" sortable></el-table-column>
          <el-table-column prop="committeeName" label="居委会" sortable></el-table-column>
          <el-table-column prop="name" label="小区" sortable></el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button
                @click="detailsCommunity(scope.row)"
                type="primary"
                size="mini"
              >
                详情
              </el-button>
              <el-button
                @click="deleteCommunity(scope.row)"
                type="danger"
                size="mini"
              >
                移除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
       
      </el-row>
    </el-container>
    <el-dialog title="分组" :visible.sync="dialogVisible" width="50%">
      <el-cascader
      style="width:100%"
      clearable
      collapse-tags
      v-model="treeValue"
      :options="allTree"
      :props='{value:"value",label:"label",multiple: true }'
     ></el-cascader>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" @click="dialogVisible = false">关闭</el-button>
        <el-button type="primary" @click="doAddCommunity">提交</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="分组详情"
      :visible.sync="detailsVisible"
      width="50%">
      <el-form label-width="80px">
        <el-form-item label="省：">
          {{details.provinceName}}
        </el-form-item>
        <el-form-item label="市：">
          {{details.cityName}}
        </el-form-item>
        <el-form-item label="区：">
          {{details.districtName}}
        </el-form-item>
        <el-form-item label="街道：">
          {{details.streetName}}
        </el-form-item>
        <el-form-item label="居委会：">
          {{details.committeeName}}
        </el-form-item>
        <el-form-item label="小区：">
          {{details.name}}
        </el-form-item>
        <el-form-item label="备注：">
          <div v-html="details.remark"></div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="detailsVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      detailsVisible: false,
      details:{},
      dialogVisible: false,
      editDialogVisible: false,
      rules: {
        name: [{required: true, message: "组名不能为空", trigger: "blur"}],
      },
      group: {
        guid: "",
        name: "",
        communityGuidList: [],
        communityList: [],
      },
      lookUp: ["province", "city", "district", "street", "committee", "community"],
      provinceList: [],
      cityList: [],
      committeeList: [],
      communityList: [],
      districtList: [],
      streetList: [],
      provinceGuid: "",
      provinceName: "",
      cityGuid: "",
      cityName: "",
      districtGuid: "",
      districtName: "",
      streetGuid: "",
      streetName: "",
      committeeGuid: "",
      committeeName: "",
      communityGuid: "",
      communityName: "",
      remark: "",
      allTree: [],
      treeValue: ['','','','','','']
    }
  },
  created() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.init();
  },
  methods: {
    init() {
      let self = this;
      self.getGroupDetail(self.$route.query.guid);
      // self.getProvinceList();
      self.getAllTree()
    },
    getGroupDetail(guid) {
      let self = this;
      self.$axios.get("/community-group/get/" + guid, {
        headers: { token: self.userInfo.token },
      }).then(function(response) {
        console.log("response.data.data", response.data.data);
        self.group.name = response.data.data.name;
        self.group.guid = response.data.data.guid;
        self.group.communityList = self.$underscore.clone(response.data.data.communityList);
        self.group.communityGuidList = self.$underscore.map(self.group.communityList, function(_el) {
          return _el.guid;
        });
        console.log('group');
        console.log(self.group);
      }).catch(function (error) {
        self.$message({
          message: "分组列表获取失败："+error.response.data.message,
          type: "warning",
        });
      });
    },
    createGroup() {
      let self = this;
      self.dialogVisible = true;
    },
    getDetail(row) {
      let self = this;
      console.log("row = ", row);
      self.$axios.get("/community-group/get/" + row.guid, {
        headers: { token: self.userInfo.token },
      }).then(function(response) {
        console.log("response.data.data", response.data.data);
        // self.currentGroup = response.data.data;
        self.editDialogVisible = true;
      }).catch(function (error) {
        self.$message({
          message: "分组详情获取失败："+error.response.data.message,
          type: "warning",
        });
      });
    },
    // 分发小区 START
    getAllTree() {
      let self = this
      self.$axios.get('/community/tree',{
        headers: { token: self.userInfo.token },
      }).then(function(res) {
        self.getTreeData(res.data.data)
        self.allTree = res.data.data
      }).catch(function (error) {
        self.$message({
          message: "列表获取失败：" + error.response.data.message,
          type: "warning",
        });
      })
    },
    getTreeData(data){
      for(var i=0;i<data.length;i++){
          if(data[i].children.length<1){
              data[i].children=undefined;
          }else {
              this.getTreeData(data[i].children);
          }
      }
      return data;
    },
    getProvinceList() {
      let self = this;
      self.$axios.get("/province/list", {
        headers: { token: self.userInfo.token },
      }).then(function(response) {
        self.provinceList = response.data.data;
        console.log("self.provinceList = ", self.provinceList);
      }).catch(function (error) {
        self.$message({
          message: "省列表获取失败：" + error.response.data.message,
          type: "warning",
        });
      });
    },
    provinceChange() {
      let self = this;
      console.log("provinceChange");
      // self.getCityList(self.provinceGuid);
      self.provinceName = self.$underscore.find(self.provinceList, function(_el) {
        return _el.guid == self.provinceGuid;
      }).name;
    },
    getCityList() {
      let self = this;
      self.$axios.get("/city/list?provinceGuid=" + self.provinceGuid, {
        headers: { token: self.userInfo.token },
      }).then(function(response) {
        self.cityList = response.data.data;
        self.districtList= "",
        self.streetList= "",
        self.committeeList= "",
        self.communityList= "",
        console.log("self.provinceList = ", self.cityList);
        self.loopRun("city");
      }).catch(function (error) {
        self.$message({
          message: "城市列表获取失败：" + error.response.data.message,
          type: "warning",
        });
      });
    },
    getDistrictList() {
      let self = this;
      self.$axios
        .get("/district/list?cityGuid=" + self.cityGuid, {
          headers: { token: self.userInfo.token },
        })
        .then(function (response) {
          self.cityName = self.$underscore.find(self.cityList, function(_el) {
            return _el.guid == self.cityGuid;
          }).name;
          self.districtList = response.data.data;
          self.streetList= "",
          self.committeeList= "",
          self.communityList= "",
          console.log("self.districtList = ", self.districtList)
          self.loopRun("district");
        })
        .catch(function (error) {
          self.$message({
            message: "区列表获取失败：" + error.response.data.message,
            type: "warning",
          });
        });
    },
    getStreetList() {
      let self = this;
      return self.$axios.get("/street/list?districtGuid=" + self.districtGuid, {
          headers: { token: self.userInfo.token },
        })
    },
    getCommitteeList() {
      let self = this;
      self.$axios
        .get("/committee/list?streetGuid=" + self.streetGuid, {
          headers: { token: self.userInfo.token },
        })
        .then(function (response) {
          self.committeeList = response.data.data;
          self.communityList= "",
          self.streetName = self.$underscore.find(self.streetList, function(_el) {
            return _el.guid == self.streetGuid;
          }).name;
          self.loopRun("committee");
        })
        .catch(function (error) {
          self.$message({
            message: "居委会列表获取失败：" + error.response.data.message,
            type: "warning",
          });
        });
    },
    getCommuntyList() {
      let self = this;
      self.$axios
        .get("/community/list?committeeGuid=" + self.committeeGuid, {
          headers: { token: self.userInfo.token },
        })
        .then(function (response) {
          self.communityList = response.data.data;
          self.committeeName = self.$underscore.find(self.committeeList, function(_el) {
            return _el.guid == self.committeeGuid;
          }).name;
        })
        .catch(function (error) {
          self.$message({
            message: "社区列表获取失败：" + error.response.data.message,
            type: "warning",
          });
        });
    },
    communityChange() {
      let self = this;
      console.log("self.communityList = ", self.communityList);
      console.log("self.communityName = ", self.communityName);
      self.communityName = self.$underscore.find(self.communityList, function(_el) {
        return _el.guid == self.communityGuid;
      }).name;
      self.remark = self.$underscore.find(self.communityList, function(_el) {
        return _el.guid == self.communityGuid;
      }).remark;
    },
    // 分发小区 END
    addCommunity() {
      let self = this;
      self.treeValue = ['','','','','','']
      self.dialogVisible = true;
    },
    doAddCommunity() {
      let self = this;
      console.log(self.treeValue);
      self.treeValue.forEach(item => {
        if (item[5]) {
        self.group.communityGuidList.push(item[5]);
        self.group.communityList.push({
            provinceGuid: item[0],
            // provinceName: self.provinceName,
            cityGuid: item[1],
            // cityName: self.cityName,
            districtGuid: item[2],
            // districtName: self.districtName,
            streetGuid: item[3],
            // streetName: self.streetName,
            committeeGuid: item[4],
            // committeeName: self.committeeName,
            guid: item[5],
            // name: self.communityName,
            // remark: self.remark
          })
          console.log(self.group);
          self.dialogVisible = false;
        } else {
          self.$message({
            message: "小区不能为空",
            type: "warning",
          });
        } 
      })
      self.doSubmit()
    },
    detailsCommunity(row) {
      let self = this
      self.detailsVisible = true
      self.details = row
      console.log(row);
    },
    deleteCommunity(row) {
      let self = this;
      self.group.communityGuidList = self.$underscore.reject(self.group.communityGuidList, function(_el) {
        return _el == row.guid;
      });
      self.group.communityList = self.$underscore.reject(self.group.communityList, function(_el) {
        return _el.guid == row.guid;
      });
    },
    doSubmit() {
      let self = this;
      let data = self.group;
      self.$axios
        .post("/community-group/saveOrUpdate", JSON.stringify(data), {
          headers: {
            "content-type": "application/json",
            token: self.userInfo.token,
          },
        }).then(function(response) {
          self.$message({
            message: "操作成功",
            type: "success",
          });
          console.log("response.data.data 1 =", response.data.data);
          self.init();
          // self.dialogVisible = false;
        })
    },
    doDelete() {
      let self = this;
      console.log(1);
      self
        .$confirm("确定删除 " + (self.group.name || "未命名用户") + "？")
        .then(() => {
          console.log(2);
          self.$axios
            .delete("/community-group/delete", {
              data: { guid: self.group.guid },
              headers: { token: self.userInfo.token },
            })
            .then(() => {
              self.$message({
                message: "删除成功",
                type: "success",
              });
              self.$router.push({ name: "ProvinceGroupList" });
            })
            .catch((error) => {
              self.$message({
                message: error.response.data.message,
                type: "warning",
              });
            });
        })
        .catch(() => {});
    },
    loopRun(element) {
      console.log("in loopRun");
      let self = this;
      let _index = self.lookUp.indexOf(element);
      console.log("_index = ", _index);
      let elementArray = self.lookUp.slice(_index);
      console.log("elementArray = ", elementArray);
      // for (let i = 0; i < self.lookUp.length; i++) {
      //   console.log(self.lookUp[i] + "Guid = " + self[self.lookUp[i]+"Guid"] );
      // }
      console.log("======");
      for (let i = 0; i < elementArray.length; i++) {
        console.log(elementArray[i] + "Guid = ", self[elementArray[i] + "Guid"]);
        self[elementArray[i] + "Guid"] = "";
        console.log(elementArray[i] + "Guid = ", self[elementArray[i] + "Guid"]);
      }
    },
  }
}
</script>
